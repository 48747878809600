/* General */

.card-image-wrap {
  position: relative;
  overflow: hidden;
}

.card-caption {
  text-align: center;
  font-weight: bold;
  margin-top: 5%;
  font-size: 22px;
  word-spacing: 7px;
}

.card-info {
  margin-top: 5%;
  line-height: 150%;
}

/* Home Card */

.home-card-container {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  width: 20%;
  margin: 5% 5%;
}

.home-card-container a {
  text-decoration: none;
  color: rgb(242, 242, 242);
}

.home-card-container a:hover {
  color: rgb(11, 87, 208);
}

.home-card-image {
  display: block;
  width: 60%;
  margin: 0 20%;
  object-fit: contain;
  border-radius: 50%;
}

/* Book card */

.book-card-container {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  width: 30%;
  margin: 5% 5%;
}

.book-card-image {
  display: block;
  width: 80%;
  margin: 0 10%;
  object-fit: contain;
}

/* Media Queries */

@media screen and (max-width: 960px) {
  .home-card-container {
    width: 50%;
    margin: 20% 5%;
  }

  .book-card-container {
    width: 90%;
    margin: 20% 0;
  }

  .book-card-image {
    margin: 5% 0 0 0;
    border-radius: 50%;
  }
}
