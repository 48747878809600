.characters {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.characters img {
    width: 100%;
    margin: 5%;
}
