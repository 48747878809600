.hero-container {
  height: 100vh;
  width: 100%;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  overflow: hidden; */
  position: relative;
}

/* .hero-image {
  position: relative;
  overflow: hidden;
} */

/* .banner-logo {
  transform: scale(0.37);
} */

/* .ribbon {
  position: absolute;
  padding: 1em 2em;
  z-index: 99;
  text-align: center;
  letter-spacing: 2px;
  font-size: 14px;
  pointer-events: auto;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 3px;
  background-color: rgba(45,45,45,255);
  color: rgb(0, 0, 0);
  top: 0px;
  right: 0px;
  transform: translateY(-100%) rotate(90deg) translateX(70.7107%) rotate(-45deg);
  transform-origin: right bottom;
  height: 40px;
  width: 250px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
} */

/* .title {
  width: 100%;
  margin-bottom: 5%;
}

.hero-container > p {
  margin: 1% 5%;
  padding: 0 10%;
  color: rgb(242, 242, 242);
  font-size: 20px;
} */

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-banner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: -10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.video-banner img {
  margin-top: 5%;
  width: 40%;
}

.video-banner p {
  font-size: 20px;
  padding: 2% 25% 0;
  text-align: center;
  word-spacing: 3px;
}

.video-banner small {
  margin-top: 2%;
}

.video-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.video-buttons button {
  word-spacing: 8px;
  width: 20%;
  padding: 1%;
  margin: 1%;
  font-size: 24px;
  border-radius: 5px;
  background-color: rgba(42, 42, 46, 1);
  color: rgb(242, 242, 242);
}

.video-buttons button a {
  text-decoration: none;
  color: rgb(242, 242, 242);
}

.video-buttons button:hover {
  background-color: rgb(11, 87, 208);
  color: rgb(242, 242, 242);
  cursor: pointer;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

@media screen and (max-width: 1200px) {
  .hero-container > p {
    font-size: 20px;
  }
}

@media screen and (max-width: 960px) {
  .hero-container > p {
    font-size: 18px;
    padding: 0 5%;
  }

  .video-banner img {
    width: 70%;
  }

  .video-buttons button {
    width: 30%;
    padding: 2%;
    margin: 1%;
  }
}

@media screen and (max-width: 700px) {
  .hero-container > p {
    font-size: 12px;
  }

  .video-banner img {
    width: 80%;
    margin-bottom: 5%;
  }

  .video-banner p {
    font-size: 15px;
    padding: 5% 15% 0;
  }

  .video-banner small {
    margin-top: 5%;
  }

  .video-buttons {
    flex-direction: column;
  }

  .video-buttons button {
    width: 60%;
    padding: 2%;
    margin: 1%;
    font-size: 20px;
  }

/* 
  .banner-logo {
    transform: scale(0.2);
  } */
  
  /* .ribbon {
    position: absolute;
    padding: 1em 2em;
    z-index: 99;
    text-align: center;
    letter-spacing: 2px;
    font-size: 14px;
    pointer-events: auto;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 3px;
    background-color: rgba(45,45,45,255);
    color: rgb(0, 0, 0);
    top: 0px;
    right: 0px;
    transform: translateY(-100%) rotate(90deg) translateX(70.7107%) rotate(-45deg);
    transform-origin: right bottom;
    height: 35px;
    width: 150px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
  } */
}