/* General */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
body {
  -ms-overflow-style: none;
}

.home,
.about,
.info,
.book,
.screening,
.screening-form,
.contact,
.media-pack,
.trailer,
.press,
.podcasts,
.bonus,
.gallery,
.privacy-policy {
  background: rgb(0, 0, 0);
  color: rgb(242, 242, 242);
}

/* Home */

.home-bottom-section {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.news-section {
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  width: 50%;
  margin: 5% 5%;
}

.news-section > h3 {
  font-size: 35px;
  margin-bottom: 10%;
}

.news-section > p {
  font-size: 20px;
  margin-bottom: 5%;
}

.news-section a {
  text-decoration: none;
  color: rgb(242, 242, 242);
}

.news-section a:hover {
  color: rgb(30, 59, 155);
}

.news-section img {
  width: 40%;
  border: 1px solid white;
  transition: transform 0.6s;
}

.news-section img:hover {
  transform: scale(1.1);
}

/* Media Pack */

.media-pack {
  height: 100%;
  padding-bottom: 15%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
}

.media-pack h1 {
  margin: 5% 0 7%;
  font-size: 40px;
}

.media-pack button {
  width: 300px;
  padding: 5%;
  margin: 20% 0 10%;
  font-size: 30px;
  border-radius: 5px;
}

.media-pack button:hover {
  background-color: rgb(11, 87, 208);
  color: rgb(242, 242, 242);
  cursor: pointer;
}

/* Trailer */

.trailer {
  height: 100%;
  padding-bottom: 15%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
}

.trailer h1 {
  margin: 5% 0;
  font-size: 40px;
}

.trailer video {
  margin: 5% 0;
  width: 60%;
  border-radius: 5px;
  border: 1px solid whitesmoke;
}

/* Press */

.press {
  height: 100%;
  padding-bottom: 15%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
}

.press h1 {
  margin: 5% 0 7%;
  font-size: 40px;
}

.mail-online-logo {
  width: 50%;
  margin-top: 5%;
}

.mail-online-p {
  font-size: 55px;
  margin: 2% 25% 5%;
}

.mail-online-link {
  font-size: 20px;
  text-decoration: none;
  color: rgb(242, 242, 242);
  margin-bottom: 5%;
}

.mail-online-link:hover {
  color: rgb(30, 59, 155);
}

.postcard-two {
  width: 60%;
  margin: 5% 20% 0 20%;
}

.forbes-logo {
  width: 50%;
  margin-top: 5%;
}

.forbes-p {
  font-size: 55px;
  margin: 2% 25% 5% 27%;
}

.forbes-article {
  width: 60%;
  margin: 0 0 2% 0;
}

.forbes-link {
  font-size: 20px;
  text-decoration: none;
  color: rgb(242, 242, 242);
  margin-bottom: 5%;
}

.forbes-link:hover {
  color: rgb(30, 59, 155);
}

.adam-article {
  margin: 5% 20%;
  display: flex;
  flex-flow: row;
  align-items: start;
  justify-content: center;
}

.adam-pic {
  width: 45%;
}

.adam-text {
  margin-left: 2%;
  display: flex;
  flex-flow: column;
  align-items: start;
  justify-content: center;
}

.adam-text a {
  margin-top: 10%;
  font-size: 25px;
  text-decoration: none;
  color: rgb(242, 242, 242);
}

.adam-text a:hover {
  color: rgb(30, 59, 155);
}

.adam-p {
  font-size: 55px;
  margin-bottom: 2%;
}

/* Podcasts */

.podcasts {
  height: 100%;
  padding-bottom: 15%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
}

.podcasts h1 {
  margin: 5% 0 7%;
  font-size: 40px;
}

.postcard-one {
  width: 60%;
  margin: 5% 20%;
}

.jonny-podcast {
  margin: 5% 20%;
  display: flex;
  flex-flow: row;
  align-items: start;
  justify-content: center;
}

.jonny-pic {
  width: 45%;
}

.jonny-text {
  margin-left: 2%;
  display: flex;
  flex-flow: column;
  align-items: start;
  justify-content: center;
}

.jonny-text a {
  margin-top: 10%;
  font-size: 25px;
  text-decoration: none;
  color: rgb(242, 242, 242);
}

.jonny-text a:hover {
  color: rgb(30, 59, 155);
}

.jonny-p {
  font-size: 55px;
  margin-bottom: 2%;
}

/* Bonus Material */

.bonus {
  height: 100%;
  padding-bottom: 15%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
}

.bonus h1 {
  margin: 5% 0 7%;
  font-size: 40px;
}

.bonus p {
  margin: 2% 0;
  font-size: 20px;
  padding: 0 20%;
}

.bonus video {
  margin: 5% 0;
  width: 60%;
  border-radius: 5px;
  border: 1px solid whitesmoke;
}

/* About */

.about {
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  word-spacing: 3px;
}

.about h1 {
  font-size: 40px;
  margin: 5%;
}

.film-banner {
  width: 60%;
}

#research {
  padding: 5% 20% 20%;
  display: grid;
  grid-template-rows: 60% 40%;
  grid-template-columns: 1fr;
  grid-template-areas:
    "text"
    "video";
}

#research-text {
  grid-area: text;
  font-size: 20px;
}

#research-text p {
  padding-bottom: 2%;
}

#research-video {
  grid-area: video;
}

#research-video video {
  width: 80%;
  margin: 5% 10%;
}

#characters {
  font-size: 20px;
  padding: 5% 20%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
}

#puppets-image {
  height: 600px;
  width: 100%;
  margin-bottom: 10%;
  background-image: url("./images/Puppets.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#characters p {
  padding-bottom: 2%;
}

.synopsis p {
  margin-top: 2%;
  font-size: 20px;
  padding: 0 20%;
}

.synopsis {
  margin-bottom: 5%;
}

/* Info (Production) */

.info {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.info h1 {
  font-size: 40px;
  text-align: center;
  padding-top: 5%;
}

.info h2 {
  font-size: 30px;
  text-align: center;
}

.info h3,
.info h4 {
  margin-bottom: 2%;
  word-spacing: 7px;
}

.info h4 {
  font-size: 16px;
}

#team {
  margin: 5% 20%;
  display: grid;
  font-size: 18px;
  grid-template-rows: 450px 450px 450px 450px 450px 450px 450px 450px 450px 450px 450px 450px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "zoomImage zoom"
    "kate kateImage"
    "sallyImage sally"
    "nigel nigelImage"
    "nickImage nick"
    "rachel rachelImage"
    "jenImage jen"
    "erran erranImage"
    "piniImage pini"
    "matt mattImage"
    "morganImage morgan"
    "ace aceImage"
    "markImage mark";
}

#zoom-image {
  min-height: 400px;
  grid-area: zoomImage;
  margin: 15% 0 0 0;
  background-image: url("./images/ZoomProfile.jpg");
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#zoom-profile {
  grid-area: zoom;
  padding: 15% 0 5% 5%;
}

#zoom-card {
  display: none;
}

#kate-image {
  min-height: 400px;
  grid-area: kateImage;
  margin: 15% 0 0 0;
  background-image: url("./images/KateProfile.jpg");
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#kate-profile {
  grid-area: kate;
  padding: 15% 5% 5% 0;
}

#kate-card {
  display: none;
}

#sally-image {
  min-height: 400px;
  grid-area: sallyImage;
  margin: 15% 0 0 0;
  background-image: url("./images/SallyProfile.jpg");
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#sally-profile {
  grid-area: sally;
  padding: 15% 0 5% 5%;
}

#sally-card {
  display: none;
}

#nigel-image {
  min-height: 400px;
  grid-area: nigelImage;
  margin: 15% 0 0 0;
  background-image: url("./images/NigelProfile.jpg");
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#nigel-profile {
  grid-area: nigel;
  padding: 15% 5% 5% 0;
}

.ls-logo {
  width: 250px;
  margin-top: 5%;
  transition: width 0.6s;
}

.ls-logo:hover {
  width: 275px;
}

.ls-logo-mobile {
  display: none;
}

#nigel-card {
  display: none;
}

#nick-image {
  min-height: 400px;
  grid-area: nickImage;
  margin: 15% 0 0 0;
  background-image: url("./images/NickProfile.png");
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#nick-profile {
  grid-area: nick;
  padding: 15% 0 5% 5%;
}

#nick-card {
  display: none;
}

#rachel-image {
  min-height: 400px;
  grid-area: rachelImage;
  margin: 15% 0 0 0;
  background-image: url("./images/RachelProfile.jpg");
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#rachel-profile {
  grid-area: rachel;
  padding: 15% 5% 5% 0;
}

#rachel-card {
  display: none;
}

#jen-image {
  min-height: 400px;
  grid-area: jenImage;
  margin: 15% 0 0 0;
  background-image: url("./images/JenniferProfile.jpg");
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#jen-profile {
  grid-area: jen;
  padding: 15% 0 5% 5%;
}

#jen-card {
  display: none;
}

#erran-image {
  min-height: 400px;
  grid-area: erranImage;
  margin: 15% 0 0 0;
  background-image: url("./images/ErranProfile.jpg");
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#erran-profile {
  grid-area: erran;
  padding: 15% 5% 5% 0;
}

#erran-card {
  display: none;
}

#pini-profile {
  grid-area: pini;
  padding: 15% 0 5% 5%;
}

#pini-image {
  min-height: 400px;
  grid-area: piniImage;
  margin: 15% 0 0 0;
  background-image: url("./images/PiniProfile.jpg");
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#pini-card {
  display: none;
}

#matt-profile {
  grid-area: matt;
  padding: 15% 5% 5% 0;
}

#matt-image {
  min-height: 400px;
  grid-area: mattImage;
  margin: 15% 0 0 0;
  background-image: url("./images/MattProfile.jpg");
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#matt-card {
  display: none;
}

#morgan-image {
  min-height: 400px;
  grid-area: morganImage;
  margin: 15% 0 0 0;
  background-image: url("./images/MorganProfile.jpg");
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#morgan-profile {
  grid-area: morgan;
  padding: 15% 5% 0 5%;
}

#morgan-card {
  display: none;
}

#ace-image {
  min-height: 400px;
  grid-area: aceImage;
  margin: 15% 0 0 0;
  background-image: url("./images/AceProfile.jpg");
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#ace-profile {
  grid-area: ace;
  padding: 15% 5% 5% 0;
}

#ace-card {
  display: none;
}

#mark-image {
  min-height: 400px;
  grid-area: markImage;
  margin: 15% 0 0 0;
  background-image: url("./images/MarkProfile.jpg");
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#mark-profile {
  grid-area: mark;
  padding: 15% 0 5% 5%;
}

#mark-card {
  display: none;
}

#aspirations {
  padding: 5% 20%;
  display: grid;
  grid-template-rows: 500px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "image text";
}

#aspirations-image {
  grid-area: image;
  background-image: url("./images/AspirationImage.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#aspirations-text {
  grid-area: text;
}

#aspirations-text p {
  font-size: 20px;
  margin: 5% 0 5% 5%;
}

#music-text {
  padding: 5% 20%;
}

#music-text p {
  font-size: 20px;
  margin-top: 2%;
}

/* Screening */

.screening {
  padding: 5% 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

#screening-banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.banner {
  width: 80%;
  margin: 5% 10%;
}

.cinema-link {
  font-size: 20px;
  text-decoration: none;
  color: rgb(242, 242, 242);
  margin: 5% 0;
}

.cinema-link:hover {
  color: rgb(30, 59, 155);
}


.screening h1 {
  font-size: 40px;
}

.screening p {
  margin-top: 5%;
  font-size: 20px;
  word-spacing: 3px;
}

.screening button {
  width: 150px;
  padding: 5%;
  margin: 5%;
  font-size: 24px;
  border-radius: 5px;
}

.screening button:hover {
  background-color: rgb(11, 87, 208);
  color: rgb(242, 242, 242);
  cursor: pointer;
}

.screening-form {
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.screening-form h1 {
  font-size: 40px;
  margin-top: 5%;
  word-spacing: 12px;
}

.screening-form p {
  margin-top: 5%;
  font-size: 20px;
}

.screening-form form {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-top: 5%;
  padding: 20px;
}

.screening-form form > input {
  width: 80%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.form-button {
  width: 10%;
  margin: 5%;
  padding: 1%;
  font-size: 24px;
  border-radius: 5px;
}

.form-button:hover {
  background-color: rgb(11, 87, 208);
  color: rgb(242, 242, 242);
  cursor: pointer;
}

/* Book */

.book {
  height: 100%;
  text-align: center;
}

.book h1 {
  margin-top: 5%;
  font-size: 40px;
}

.book button {
  width: 30%;
  margin: 5%;
  padding: 2%;
  cursor: pointer;
  font-size: 25px;
  font-weight: bold;
  border-radius: 10px;
  background-color: rgba(42, 42, 46, 1);
  color: rgb(242, 242, 242);
}

.book button:hover {
  background-color: rgb(11, 87, 208);
}

.book-container {
  padding: 5% 10%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  color: rgb(242, 242, 242);
}

.book-container a {
  text-decoration: none;
  color: rgb(242, 242, 242);
}

.book-container a:hover {
  color: rgb(11, 87, 208);
}

/* Contact */

.contact {
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.contact h1 {
  font-size: 40px;
  margin-top: 5%;
}

.contact p {
  margin-top: 5%;
  font-size: 20px;
  padding: 0 20%;
}

.contact form {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-top: 5%;
  padding: 20px;
}

.contact form > input,
.contact form > textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

/* Gallery */

.gallery {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gallery h1 {
  font-size: 40px;
  margin: 3% 0;
}

.gallery p {
  font-size: 20px;
  margin: 1%;
}

.gallery-img {
  width: 60%;
  margin: 5% 20%;
}

.jw3-p {
  padding-bottom: 5%;
}

/* Privacy Policy */

.privacy-policy-container {
  color: #fff;
  height: 100%;
  width: 100%;
  padding: 5% 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  object-fit: contain;
  line-height: 150%;
}

.privacy-policy-container ul {
  margin: 2%;
}

.privacy-policy-container h1 {
  margin: 5% 0;
  font-size: 60px;
}

.privacy-policy-container h2 {
  margin: 5% 0;
  font-size: 40px;
}

.privacy-policy-container h3 {
  margin: 5% 0;
  font-size: 25px;
}

.privacy-policy-container a {
  text-decoration: none;
  color: rgb(242, 242, 242);
}

.privacy-policy-container a:hover {
  color: rgb(11, 87, 208);
}

/* Media Queries */

@media screen and (max-width: 960px) {
  .media-pack h1 {
    padding-top: 10%;
  }

  .trailer h1 {
    padding-top: 10%;
    text-align: center;
  }

  .trailer video {
    width: 100%;
  }

  .postcard-one {
    width: 60%;
    margin: 10% 20%;
  }

  .postcard-two {
    width: 60%;
    margin: 10% 20% 0 20%;
  }

  .mail-online-logo {
    width: 70%;
    margin-top: 15%;
  }

  .mail-online-p {
    margin: 5% 15%;
  }

  .forbes-logo {
    width: 70%;
    margin-top: 15%;
  }

  .forbes-p {
    margin: 5% 20%;
  }

  .jonny-pic {
    width: 80%;
    margin-bottom: 10%;
  }

  .jonny-p {
    font-size: 45px;
  }

  .about {
    padding: 0 5% 5% 5%;
  }

  .about h1 {
    margin: 10% 0;
  }

  .about p {
    font-size: 18px;
    padding: 0;
  }

  .film-banner {
    width: 90%;
  }

  #puppets-image {
    height: 400px;
  }

  .synopsis {
    padding: 5%;
  }

  #team {
    margin: 5% 5%;
    font-size: 15px;
  }

  #music-text {
    padding: 10% 5%;
  }

  #research {
    padding: 5% 5% 20% 5%;
  }

  #research-text {
    font-size: 20px;
  }

  #characters {
    padding: 10% 5%;
  }

  #characters p {
    padding-bottom: 5%;
  }

  #aspirations {
    padding: 5%;
  }

  #aspirations-text p {
    margin: 0 0 0 5%;
  }

  .form-button {
    width: 20%;
  }

  .gallery h1 {
    margin: 10% 0;
  }
}

@media screen and (max-width: 770px) {
  #screening-banner {
    margin-bottom: 5%;
  }
}

@media screen and (max-width: 765px) {
  .media {
    text-align: center;
  }

  .postcard-one {
    width: 100%;
    margin: 15% 0;
  }

  .postcard-two {
    width: 100%;
    margin: 15% 0 0 0;
  }

  .mail-online-p {
    font-size: 20px;
    margin: 5% 17% 10%;
    text-align: left;
  }

  .mail-online-link {
    font-size: 18px;
  }

  .forbes-p {
    font-size: 20px;
    margin: 5% 17% 10%;
    text-align: left;
  }

  .forbes-article {
    width: 100%;
    margin: 5% 0 5% 0;
  }

  .forbes-link {
    font-size: 20px;
  }

  .adam-article,
  .jonny-podcast {
    margin: 10%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }

  .adam-text,
  .jonny-text {
    margin-top: 5%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }

  .adam-pic,
  .jonny-pic {
    width: 90%;
  }

  .adam-p,
  .jonny-p {
    font-size: 20px;
    margin: 5%;
    text-align: left;
  }

  .adam-text a,
  .jonny-text a {
    font-size: 20px;
  }

  .bonus h1 {
    margin: 15% 0 7%;
    font-size: 40px;
    text-align: center;
  }
  
  .bonus p {
    margin: 2% 0;
    font-size: 20px;
    padding: 0 5%;
  }

  .home-bottom-section {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }

  .news-section {
    width: 80%;
    margin: 10% 5% 20% 5%;
    text-align: center;
  }

  .news-section > h3 {
    font-size: 25px;
  }

  

  .info h1,
  .info h2 {
    padding: 15% 0 5% 0;
  }

  .about p {
    font-size: 16px;
  }

  #research {
    display: grid;
    grid-template-rows: 75% 25%;
    grid-template-columns: 1fr;
    grid-template-areas:
      "text"
      "video";
  }

  #research-text {
    font-size: 18px;
    margin-bottom: 20%;
  }

  #research-video video {
    width: 100%;
    margin: 2% 0;
  }

  #unknown {
    padding: 5%;
    display: grid;
    grid-template-rows: 200px 200px 200px 200px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      "unk1 unk2 unk3"
      "unk4 unk5 unk6"
      "unk7 unk8 unk9"
      "unk10 unk11 unk12";
  }

  #team {
    margin: 5% 5%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }

  #zoom-card {
    display: block;
  }

  #zoom-image {
    background-image: none;
    min-height: 1px;
  }

  #zoom-profile {
    display: none;
  }

  #kate-card {
    display: block;
  }

  #kate-image {
    background-image: none;
    min-height: 1px;
  }

  #kate-profile {
    display: none;
  }

  #sally-card {
    display: block;
  }

  #sally-image {
    background-image: none;
    min-height: 0;
  }

  #sally-profile {
    display: none;
  }

  #nigel-card {
    display: block;
  }

  .ls-logo-mobile {
    display: block;
    width: 200px;
    margin: 5% 20%;
  }

  #nigel-image {
    background-image: none;
    min-height: 0;
  }

  #nigel-profile {
    display: none;
  }

  #nick-card {
    display: block;
  }

  #nick-image {
    background-image: none;
    min-height: 0;
  }

  #nick-profile {
    display: none;
  }

  #rachel-card {
    display: block;
  }

  #rachel-image {
    background-image: none;
    min-height: 0;
  }

  #rachel-profile {
    display: none;
  }

  #jen-card {
    display: block;
  }

  #jen-image {
    background-image: none;
    min-height: 0;
  }

  #jen-profile {
    display: none;
  }

  #erran-card {
    display: block;
  }

  #erran-image {
    background-image: none;
    min-height: 1px;
  }

  #erran-profile {
    display: none;
  }

  #pini-card {
    display: block;
  }

  #pini-image {
    background-image: none;
    min-height: 1px;
  }

  #pini-profile {
    display: none;
  }

  #matt-card {
    display: block;
  }

  #matt-image {
    background-image: none;
    min-height: 0;
  }

  #matt-profile {
    display: none;
  }

  #morgan-card {
    display: block;
  }

  #morgan-image {
    background-image: none;
    min-height: 1px;
  }

  #morgan-profile {
    display: none;
  }

  #mark-card {
    display: block;
  }

  #mark-image {
    background-image: none;
    min-height: 0;
  }

  #mark-profile {
    display: none;
  }

  #ace-card {
    display: block;
  }

  #ace-image {
    background-image: none;
    min-height: 0;
  }

  #ace-profile {
    display: none;
  }

  .team-card-container {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0 5%;
  }

  .team-card-image {
    display: block;
    width: 80%;
    margin: 5% 10%;
    object-fit: contain;
    border-radius: 50%;
  }

  .team-card-container a {
    text-decoration: none;
    color: rgb(242, 242, 242);
  }

  .team-card-container a:hover {
    color: rgb(11, 87, 208);
  }

  #aspirations {
    padding: 5%;
    display: grid;
    grid-template-rows: 450px 450px;
    grid-template-columns: 1fr;
    grid-template-areas:
      "text"
      "image";
  }

  #aspirations-text {
    margin-top: 10%;
  }

  #aspirations-text p {
    font-size: 18px;
    margin: 0;
  }

  #aspirations-image {
    margin: 20% 0;
  }

  #music-text p {
    font-size: 18px;
  }

  .screening h1 {
    margin-bottom: 10%;
  }

  .screening p {
    padding: 5%;
    font-size: 20px;
    text-align: center;
  }

  .screening-form h1 {
    font-size: 40px;
    text-align: center;
  }

  .screening-form p {
    padding: 0 5%;
    text-align: center;
  }

  .screening-form form {
    width: 90%;
  }

  .form-button {
    width: 40%;
  }

  .book button {
    width: 80%;
    margin: 5% 0;
    font-size: 20px;
  }

  .book-container {
    padding: 0;
    margin: 5%;
    flex-flow: column;
  }

  .book-container img {
    width: 100%;
  }

  .contact h1 {
    margin-bottom: 10%;
  }

  .contact p {
    padding: 0 5%;
    text-align: center;
  }

  .contact form {
    width: 90%;
  }

  .gallery p {
    font-size: 16px;
    margin: 2%;
  }

  .privacy-policy-container {
    padding: 5% 10%;
    line-height: 160%;
  }

  .privacy-policy-container ul {
    margin: 4%;
  }

  .privacy-policy-container h1 {
    margin: 5% 0;
    font-size: 35px;
  }

  .privacy-policy-container h2 {
    margin: 5% 0;
    font-size: 30px;
  }

  .privacy-policy-container h3 {
    margin: 5% 0;
    font-size: 20px;
  }
}
