.navbar {
  background: rgb(17, 17, 17);
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  max-width: 1900px;
}

.navbar-logo {
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.navbar-logo img {
  width: 140px;
  height: auto;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style: none;
  text-align: center;
  width: 60vw;
  margin-right: 5%;
  font-weight: bold;
}

.dropdown-button {
  color: rgb(242, 242, 242);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  cursor: pointer;
}

.nav-item {
  height: 60px;
}

.nav-links {
  color: rgb(242, 242, 242);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1.5rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid rgb(30, 59, 155);
  transition: all 0.2s ease-out;
}

.nav-links.mobile-link {
  display: none;
}

.fa-bars {
  color: rgb(242, 242, 242);
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .nav-item {
    height: 60px;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 50%;
    height: 90vh;
    position: absolute;
    top: 60px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    font-weight: bold;
    overflow-y: scroll;
  }

  .dropdown-button {
    display: none;
  }

  .nav-links {
    text-align: center;
    padding: 1.2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: rgb(242, 242, 242);
    color: #242424;
    border-radius: 0;
  }

  .nav-links.mobile-link {
    display: block;
  }

  .navbar-logo {
    position: absolute;
    top: 10%;
    left: 0;
  }

  .navbar-logo img {
    width: 120px;
    margin-top: 10px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: rgb(242, 242, 242);
    font-size: 2rem;
  }
}
