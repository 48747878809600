.footer-container {
  background: rgb(17, 17, 17);
  padding: 5% 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(242, 242, 242);
  word-spacing: 2px;
}

.footer-container p {
  font-size: 18px;
}

.footer-container a {
  text-decoration: none;
  color: rgb(242, 242, 242);
}

.footer-container a:hover {
  color: rgb(30, 59, 155);
}

.lemon-soul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ls-logo-footer {
  width: 150px;
  margin: 0 0 0 10%;
  transition: transform 0.6s;
}

.ls-logo-footer:hover {
  transform: scale(1.1);
}

.social-icon-link {
  color: rgb(242, 242, 242);
  font-size: 24px;
}

.social-icon-link:hover {
  color: rgb(30, 59, 155);
  transition: 0.3s ease-out;
}

.social-media-wrap {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1000px;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
}

@media screen and (max-width: 840px) {
  .footer-container {
    text-align: center;
    padding: 10% 5%;
  }

  .lemon-soul {
    flex-direction: column;
  }
  
  .ls-logo-footer {
    margin: 15% 0 0 0;
  }

  .social-media-wrap {
    flex-direction: column;
    margin-top: 10%;
  }

  .website-rights {
    margin-top: 10%;
    text-align: center;
  }
}
